<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
	import { assets } from '$app/paths';
	import type { Writable } from 'svelte/store';
	import { onMount } from 'svelte';
	import type { TinySliderInstance } from 'tiny-slider';
    import Image from '$lib/components/Image.svelte';

	let tnsEl: HTMLElement;
	let tnsInstance: TinySliderInstance;
	export let carouselIndex: number;

	export let carouselNavIndex: Writable<number>;
	let carouselActive = false;

	const goToPrev = () => tnsInstance?.goTo('prev');
	const goToNext = () => tnsInstance?.goTo('next');

	onMount(async () => {
		if (typeof window !== 'undefined') {
			const tns = await import('tiny-slider/src/tiny-slider');
			tnsInstance = tns.tns({
				container: tnsEl,
				items: 1,
				slideBy: 'page',
				autoplay: false,
				controls: false,
				nav: false,
				arrowKeys: true,
			});
			tnsInstance.events.on('indexChanged', () => {
				$carouselNavIndex = tnsInstance.getInfo().index;
			});

			carouselActive = true;
		}
	});

	$: tnsInstance?.goTo(carouselIndex);
</script>

<!-- <svelte:window on:keydown={onKeyDown} /> -->
<div class="carousel-container">
	<div class="nav-wrapper">
		<button class="nav prev" on:click={goToPrev}>
			<i class="fa-solid fa-angle-left" />
			<Image isTransparent={true} src="{assets}/img/common/carousel/bg_gradient_left" alt="" />
		</button>
		<button class="nav next" on:click={goToNext}>
			<i class="fa-solid fa-angle-right" />
			<Image isTransparent={true} src="{assets}/img/common/carousel/bg_gradient_right" alt="" />
		</button>
	</div>

	<div class="tiny-slider-wrapper" class:active={carouselActive}>
		<div class="tiny-slider" bind:this={tnsEl}>
			<slot></slot>
		</div>
	</div>
</div>

<style lang="scss">
	@use '$lib/assets/css/variables' as var;

	.carousel-container {
		position: relative;
		margin-bottom: 10px;
		width: 100%;
		&:hover .nav {
			opacity: 1;
		}
	}
	.nav-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		z-index: 10;
		pointer-events: none;
	}
	.nav {
		position: relative;
		font-size: 19px;
		cursor: pointer;
		height: 100%;
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		pointer-events: auto;
		opacity: 0;
		transition: opacity 0.3s ease;
		i {
			color: #bebdb799;
		}
		:global(img) {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
	button {
		background: transparent;
		border: none;
	}
	.tiny-slider-wrapper {
		opacity: 0;
		filter: blur(10px);
		transition: opacity .5s ease, filter .7s ease-out;
	}
	.active {
		opacity: 1;
		filter: blur(0);
	}
</style>
