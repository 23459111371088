<!-- 
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import type { Writable } from "svelte/store";

	export let carouselLength = 0;
	export let carouselNavIndex: Writable<number>;
	const dispatch = createEventDispatcher<Record<string, number>>();

	function* range(start: number, end: number): Generator<number> {
		for (let i = start; i < end; i++) {
			yield i;
		}
	}

	function updateIndex(index: number): void {
		dispatch("updateIndex", (index + carouselLength - 1) % carouselLength);
	}

</script>

<div class="pagination">
	{#each range(0, carouselLength) as index}
		<button on:click={() => updateIndex(index)}>
			<i class="fa-solid fa-diamond" class:tns-nav-active={$carouselNavIndex % carouselLength === index} />
		</button>
	{/each}
</div>

<style lang="scss">
	@use '$lib/assets/css/variables' as var;

	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
		padding: 10px;
		button {
			padding: 0;
			line-height: 1;
		}
		i {
			font-size: 12px;
			color: #bebdb799;
			cursor: pointer;
		}
		.tns-nav-active {
			color: var.$color-accent;
		}
	}
    button {
		background: transparent;
		border: none;
	}
</style>
